<template>
  <b-row>
    <b-col cols="12">
      <template>
        <!-- <div class="card">
          <b-card-body> </b-card-body>
        </div> -->
        <div class="card">
          <div class="px-2 pt-2 d-flex flex-wrap justify-content-between">
            <div class="info-left w-30">
              <h3>Top {{ chartSelectedText }} | {{ countryName }}</h3>
            </div>
            <div class="d-flex flex-column flex-md-row w-70 w-md-100" style="gap: 5px">
              <b-form-group class="mb-0">
                <b-form-select :options="optionsChart" v-model="chartSelected">
                </b-form-select>
              </b-form-group>
              <b-form-group class="mb-0">
                <b-form-select :options="optionsType" v-model="typeSelected">
                </b-form-select>
              </b-form-group>
              <b-form-group class="mb-0">
                <b-form-select :options="optionsCategories" v-model="CategorySelected">
                </b-form-select>
              </b-form-group>
              <b-form-group class="mb-0" style="max-width: 150px !important">
                <b-form-select :options="optionsCountries" v-model="countryCode"></b-form-select>
              </b-form-group>
              <!-- <b-form-group class="mb-0">
                <b-form-datepicker :right="true" v-model="date" :min="minDate" :max="maxDate" :date-format-options="{
                  year: 'numeric',
                  month: 'short',
                  day: '2-digit',
                }"></b-form-datepicker>
              </b-form-group> -->
            </div>
          </div>
          <div v-if="result && !progress && items.length > 0" class="mb-2">

            <span class="d-flex align-items-center">
              <feather-icon class="text-primary m-1" icon="FilterIcon" size="30" />
              <b-form-tags  tag-variant="primary" class="m-1" placeholder="Filter or search by keyword"
                input-id="tags-basic" v-model="typedNiches">
              </b-form-tags>
            </span>
            <div class="d-flex flex-wrap justify-content-start filterBadgesWrapper">
              <button type="button" class="btn btn-outline-primary filterBadges rounded-pill"
                @click="filterByNiche('all')">
                All
              </button>
              <button type="button" class="btn btn-outline-primary filterBadges rounded-pill"
                @click="filterByNiche('coloring')">
                coloring
              </button>
              <button type="button" class="btn btn-outline-primary  filterBadges rounded-pill"
                @click="filterByNiche('fake call')">
                fake call
              </button>
              <button type="button" class="btn btn-outline-primary  filterBadges rounded-pill"
                @click="filterByNiche('guide')">
                guide
              </button>
              <button type="button" class="btn btn-outline-primary  filterBadges rounded-pill"
                @click="filterByNiche('vpn')">
                vpn
              </button>
            </div>
          </div>
          <!--:sort-by="sortBy"-->
          <b-table striped hover responsive class="position-relative mb-0" :per-page="perPage" :current-page="currentPage"
            :items="isFiltered ? filteredResult : items" :fields="fields" show-empty v-if="result && !progress">
            <template #empty>
              <div class="text-center" v-if="!isFiltered">
                <feather-icon icon="AlertTriangleIcon" class="" size="60" />
                <h3 class="mt-1">Data Being Prepared</h3>
              </div>
              <div class="text-center" v-else>
                <feather-icon icon="AlertTriangleIcon" class="" size="60" />
                <h3 class="mt-1">No data was Found for this Niche</h3>
              </div>
            </template>
            <template #cell(icon)="data">
              <img :src="data.value" width="30" />
            </template>
            <template #cell(scoreText)="data">
              {{ data.value ? `${data.value} / 5'` : 0 }}
            </template>
            <template #cell(released)="data">
              <span v-if="data.value">
                {{ moment(data.value).format("D, MMM YYYY") }}</span>
            </template>
            <template #cell(version)="data">
              <span class="app-score badge bg-primary rounded-pill px-1 overflow-hidden"
                style="max-width: 60px; text-overflow: ellipsis">{{ data.value }}</span>
            </template>
            <template #cell(title)="data">
              <router-link style="color: inherit" :to="{
                name: 'apps-info',
                params: { id: data.item.app_id, from: 'api',route: 0,lang:'en-US',country:'ma' },
              }" target="_blank">
                {{ data.item.title }}
              </router-link>
            </template>
            <template #cell(account)="data">
              <router-link :to="{
                name: 'market-manager-accounts-search',
                query: { search: data.item.account },
              }" target="_blank">
                {{ data.item.account }}
              </router-link>
            </template>
            <template #cell(playstoreUrl)="data">
              <div class="d-flex" style="gap: 10px">
                <p>
                  <a :href="`https://play.google.com/store/apps/details?id=${data.item.app_id}`" target="_blank"
                    title="Visit on Store"><feather-icon icon="EyeIcon" size="20" /></a>
                </p>
                <p>
                  <a :href="'/myprofile/apps/' + data.item.app_id + ',ma,en-US,api,0/info'" target="_blank" title="Show More Info"
                    class="text-warning">
                    <feather-icon icon="InfoIcon" size="20" />
                  </a>
                </p>
              </div>
            </template>
          </b-table>
          <div class="text-center my-4" v-if="progress">
            <b-spinner class="spinner" variant="primary" size="60" label="Text Centered" />
          </div>
          <b-row class="m-1">
            <b-col cols="6">
              <b-form-group class="mb-0">
                <label class="d-inline-block text-sm-left mr-50">Per page</label>
                <b-form-select id="perPageSelect" v-model="perPage" size="sm" :options="pageOptions" class="w-25" />
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="center" size="sm"
                class="my-0" />
            </b-col>
          </b-row>
        </div>
      </template>
    </b-col>
  </b-row>
</template>

<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import axios from "@axios";
import moment from "moment";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import store from "@/store";

import {
  BRow,
  BCol,
  BTable,
  BAvatar,
  BBadge,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BCardBody,
  BFormDatepicker,
  BFormCheckbox,
  BSpinner,
  BFormTags,
  BIcon,
  CustomIcon,
} from "bootstrap-vue";
export default {
  components: {
    BRow,
    BCol,
    BCardCode,
    BFormTags,
    BTable,
    BAvatar,
    BBadge,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCardBody,
    BFormDatepicker,
    BFormCheckbox,
    BSpinner,
    BIcon,
  },
  data() {
    return {
      value: "",
      moment,
      perPage: 100,
      pageOptions: [10, 50, 100, 200],
      totalRows: 1,
      currentPage: 1,
      sortBy: "released",
      filter: null,
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      fields: [
        {
          key: "index",
          label: "#",
        },
        {
          key: "icon",
          label: "Icon",
        },
        {
          key: "title",
          label: "Title",
        },
        {
          key: "score",
          label: "Score",
        },
        {
          key: "max_installs",
          label: "Installs",
          sortable: true,
        },
        {
          key: "version",
          label: "Version",
        },
        { key: "account", label: "Account" },
        {
          key: "released",
          label: "Released",
          sortable: true,
        },
        {
          key: "playstoreUrl",
          label: "",
        },
      ],
      optionsCountries: [],
      optionsChart: [
        { value: "top_free", text: "Free" },
        { value: "top_new_apps", text: "New Free" },
      ],
      optionsType: [
        { value: null, text: "All" },
        { value: "GAME", text: "Games" },
        { value: "APPLICATION", text: "Applications" },
      ],
      optionsCategories: [{ value: "OVERALL", text: "Overall" }],
      chartSelected: "top_new_apps",
      CategorySelected: "OVERALL",
      typeSelected: null,
      chartSelectedText: "New Free",
      countryCode: "ma",
      countryName: "Morocco",
      date: new Date(),
      minDate: new Date(),
      maxDate: new Date(),
      apps: [],
      items: [],
      filteredResult: [],
      typedNiches: [],
      preserveCustimizeNiche: [],
      isFiltered: false,
      niche1Count: 0,
      niche2Count: 0,
      niche3Count: 0,
      niche4Count: 0,
      totalNicheResult: null,
      counter: 0,
      result: true,
      progress: true,
      isDatabase: false,
    };
  },
  computed: {
    // tagColor() {
    //   if (store.state.appConfig.layout.skin === 'dark') {
    //     return "#000"
    //   }
    //   return "#fff"
    // },
  },
  watch: {
    typedNiches: {
      handler(newVal) {
        this.filterByCustomizedNiche(newVal);
      if(this.typedNiches.length === 0){
        this.totalRows = this.items.length;
      }
      },
      deep: true, 
    },
    // tagColor(oldValue, newValue) {
    //   const tags = document.querySelector('.b-form-tag');
    //   tags.style.color = newValue;
    // },
    countryCode(value) {
      this.progress = true;
      this.isFiltered = false;
      this.filteredResult = [];
      this.preserveCustimizeNiche = [];
      this.typedNiches = null;
      this.countryName = this.optionsCountries.find(
        (option) => option.value === this.countryCode
      ).text;

      this.getApps();
    },
    typeSelected() {
      this.progress = true;
      this.isFiltered = false;
      this.filteredResult = [];
      this.preserveCustimizeNiche = [];
      this.typedNiches = null;

      if (this.typeSelected === "APPLICATION") {
        this.CategorySelected = "OVERALL";
        this.optionsCategories = [
          { value: "OVERALL", text: "Overall" },
          { value: "APPLICATION", text: "Application" },
          { value: "ENTERTAINMENT", text: "Entertainment" },
          { value: "TOOLS", text: "Tools" },
          { value: "EDUCATION", text: "Education" },
          { value: "MUSIC_AND_AUDIO", text: "Music & Audio" },
          { value: "VIDEO_PLAYERS", text: "Video Players & Editors" },
        ];
      } else if (this.typeSelected === "GAME") {
        this.CategorySelected = "GAME";
        this.optionsCategories = [
          { value: "GAME", text: "Overall" },
          { value: "GAME_ACTION", text: "Games Action" },
          { value: "GAME_CASUAL", text: "Games Casual" },
          { value: "GAME_SIMULATION", text: "Games Simulation" },
          { value: "GAME_ARCADE", text: "Games Arcade" },
          { value: "GAME_PUZZLE", text: "Games Puzzle" },
        ];
      } else {
        this.CategorySelected = "OVERALL";
        this.optionsCategories = [{ value: "OVERALL", text: "Overall" }];
      }
      this.getApps();
      this.getMinDate();
    },
    chartSelected() {
      this.progress = true;
      this.isFiltered = false;
      this.filteredResult = [];
      this.preserveCustimizeNiche = [];
      this.typedNiches = null;

      this.chartSelectedText = this.optionsChart.find(
        (option) => option.value === this.chartSelected
      ).text;

      if (this.CategorySelected.includes("GAME")) {
        this.CategorySelected = "GAME";
      } else {
        this.CategorySelected = "OVERALL";
      }
      this.getApps();
      this.getMinDate();
    },
    CategorySelected() {
      this.progress = true;
      this.isFiltered = false;
      this.filteredResult = [];
      this.preserveCustimizeNiche = [];
      this.typedNiches = null;

      this.getApps();
      this.getMinDate();
    },
    date(value) {
      this.progress = true;
      this.isFiltered = false;
      this.filteredResult = [];
      this.preserveCustimizeNiche = [];
      this.typedNiches = null;
      this.getApps();
    },
  },

  mounted() {
    this.getApps();
    this.getMinDate();
    this.getCountries();
    // this.$nextTick(() => {
    //   if (store.state.appConfig.layout.skin === 'dark') {
    //     tags.style.color = "#000";
    //   } else {
    //     tags.style.color = "#fff";
    //   }
    // });
  },
  methods: {
    showToast(variant, icon, title, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text,
          variant,
        },
      });
    },
    async filterByNiche(word) {
      this.isFiltered == false;
      if (word !== "all") {
        this.isFiltered = true;
        this.typedNiches = this.typedNiches.concat([word]);
        this.typedNiches = [...new Set(this.typedNiches)];
        this.filterByCustomizedNiche(this.typedNiches);
        this.totalRows = this.typedNiches.length;


      } else {
        this.typedNiches = [];
        this.isFiltered = false;
        this.filteredResult = this.items;
        this.totalRows = this.items.length;
      }
    },
    async filterByCustomizedNiche(niches) {
      if(niches.length === 0){
        this.filteredResult = this.items;
        return
      }
      this.totalNicheResult = 0;
      this.isFiltered = true;
      let tempFilterdResult = [];
      if (niches.length > 0) {
        for (const niche of niches) {
          const promises = this.items.map(async (app) => {
            if (await app.title.toLowerCase().includes(niche.toLowerCase())) {
              return app;
            }
            return null;
          });
          const filteredApps = await Promise.all(promises);
          tempFilterdResult = tempFilterdResult.concat(
            filteredApps.filter(Boolean)
          );
        }
        this.filteredResult = tempFilterdResult;
        this.totalNicheResult = tempFilterdResult.length;
        this.preserveCustimizeNiche = tempFilterdResult;
        tempFilterdResult = [];
      }
    },
    getCountries() {
      axios
        .get("/countries")
        .then((response) => {
          this.optionsCountries = [];
          response.data.countries.forEach((c) => {
            this.optionsCountries.push({
              value: c.code,
              text: c.name,
            });
          });
        })
        .catch(console.error);
    },
    getApps() {
      this.items = [];
      this.totalRows = 0;
      this.progress = true;
      axios
        .get(
          `/get-market-apps?country=${this.countryCode}&date=${moment(
            this.date,
            "YYYY-MM-DD"
          ).format("MMM DD, YYYY")}&chart=${this.chartSelected}&category=${this.CategorySelected
          }`
        )
        .then((response) => response.data)
        .then((data) => {

          this.progress = false;
          if (data.count > 0) {
            this.isDatabase = true;
            let apps = data.apps.sort((a, b) => {
              return a.position - b.position;
            });
            this.displayTableContent(apps);
          }
        })
        .catch(console.error);
    },

    displayTableContent(results) {
      results.forEach((item) => {
        var timestamp;
        if (item.released) {
          timestamp = Date.parse(item.released);
        }
        item.released = timestamp;
      });
      this.apps = results;
      this.items = this.addIndexToTable(this.apps);
      const niches = ["coloring", "fakecall", "guide", "vpn"];
      niches.map((word, index) => {
        this[`niche${index + 1}Count`] = this.items.filter((apps) => {
          return apps.title.toLowerCase().includes(word.toLowerCase());
        }).length;
      });
      this.totalRows = this.items.length;
    },

    addIndexToTable(table) {
      const t = table.map((item, index) => {
        item.index = index + 1;

        return item;
      });

      return t;
    },

    getMinDate() {
      axios
        .post("/get-market-apps-min-date", {
          chart: this.chartSelected,
          category: this.CategorySelected ? this.CategorySelected : "OVERALL",
        })
        .then(({ data }) => {
          if (!(data.min_date === null)) {
            this.minDate = data.min_date;
          }
        });
    },
  },


};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

.filterBadgesWrapper {
  margin-left: 4.3rem;
}

.filterBadges {
  cursor: pointer;
  margin-inline: 0.6rem !important;
  padding: 0.3rem 0.6rem !important;
}

.filterBadges:hover {
  transform: scale(1.1);
}
</style>
